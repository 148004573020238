import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

/* paginas */
import HomePage from './pages/home';
import TerminosCondiciones from './pages/terminos_condiciones';
import PoliticaPrivacidad from './pages/politica_privacidad';

/* layouts */
import PaginaLayoutRoute from './layouts/paginaLayout';

const NoMatch = ({ location }) => {

   return <h1>La dirección {location.pathname} no fue encontrada en esta web</h1>     
      
}

function RouterApp() {

  return(
        <BrowserRouter>
            <Switch>
              <PaginaLayoutRoute exact path="/" component={HomePage} />
              <PaginaLayoutRoute exact path="/home" component={HomePage} />
              <TerminosCondiciones exact path="/terminos-y-condiciones"/>
              <PoliticaPrivacidad exact path="/politica-de-privacidad"/>
            </Switch>
        </BrowserRouter>
  );
}

export default RouterApp;
