import React from 'react';
import Form_qubit from  '../components/form_qubit.js'
import executive_one from '../assets/img/executive1.png';
import executive_two from '../assets/img/executive2.png';
import executive_three from '../assets/img/executive3.png';
import maps1 from '../assets/img/maps.png';
import maps2 from '../assets/img/maps2.png';
import maps3 from '../assets/img/maps3.png';
import maps4 from '../assets/img/maps4.png';
import '../styles/home.css';

import * as Scroll from 'react-scroll';

class HomePage extends React.Component{
    
    Link      = Scroll.Link;
    Button    = Scroll.Button;
    Element   = Scroll.Element;
    Events    = Scroll.Events;
    scroll    = Scroll.animateScroll;
    scrollSpy = Scroll.scrollSpy;


    componentDidMount() {
        this.Events.scrollEvent.register('begin', function(to, element) {
          console.log('begin', arguments);
        });
    
        this.Events.scrollEvent.register('end', function(to, element) {
          console.log('end', arguments);
        });
    
        this.scrollSpy.update();

        this.registrarVisita('landing');
      }
    
    componentWillUnmount() {
        this.Events.scrollEvent.remove('begin');
        this.Events.scrollEvent.remove('end');
      }

    scrollToTop() {
        this.scroll.scrollToTop();
    }

    scrollToBottom() {
        this.scroll.scrollToBottom();
    }
    
    scrollTo() {
        this.scroll.scrollTo(300);
    }
      
    scrollMore() {
        this.scroll.scrollMore(300);
    }
    
    handleSetActive(to) {
        this.console.log(to);
    }

    registrarVisita(nombre){
        
        /*
        var data = {
            username : "user",
            password : "user"
        }
        fetch('http://74.208.137.130:8081/auth',
        {method: 'POST',body: JSON.stringify(data), headers: {
            'Content-Type': 'application/json'
        }}
        */
       
        var data = {
            password: "123456",
            userName: "jeanmaster"
        }
        fetch('http://74.208.137.130:8081/qubit.security/access/login',
        {method: 'POST', headers: {
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJyb2xlIjpbIlJPTEVfVVNFUiJdLCJzdWIiOiJ1c2VyIiwiaWF0IjoxNjcwNzE3Mzg4LCJleHAiOjE2NzA3NDYxODh9.t3RCWXKwlza1R60Q-opWXLznj1A_kluJkfJw2sNHiywzoSXxwuAJ7ufCKLjUJq7FCXEkYvVYOdceLtklnnrsXA',
            'Content-Type': 'application/json'
        },body: JSON.stringify(data)}
        ).then(res=>res.text()).then(
        (data)=>{
            console.log("Respueta", data);
        },
        (error)=>{
            console.log('error');
        }
        );
    }
    

    render(){
        return(
            <div className='container_lpage'>
                <div id='home'></div>
                <div className="row g-0 container_header">
                    {/* <img src={img_header} className="header_img" /> */}
                    <div className='col-md-6 col-sm-12 col-xs-12 col-xs-12'></div>
                    <div className='col-md-6 col-sm-12 col-xs-12 header_tittle'>
                        <div className="header_text">
                            <h1>Tu segunda fuente de ingreso</h1>
                            <p>Ingreso pasivo y recurrente en el tiempo.</p>
                            <this.Link to="leer_mas" spy={true} smooth={true} offset={50} duration={500} className="header-link">LEER MÁS <i className="fas fa-arrow-right"></i></this.Link>
                            <div id='leer_mas'></div>
                        </div>
                    </div>
                </div>
                
                <div className='row g-0 triangle_header'>
                    <div className='col-6 triangle_header_one'></div>
                    <div className='col-6 triangle_header_two'></div>
                </div>
                <div className='welcome_container'>
                    <div className="row g-0 container_body_p1">
                        <div className='col-md-7 col-sm-12 body_p1_text'>
                            <h4>BIENVENIDOS A QUBIT CAPITAL,</h4>
                            <h4>UNA NUEVA FORMA DE INVERTIR</h4>
                            <br/>
                            <p>Nuestra visión es rentabilizar los ahorros de las familias y empresas ofreciendo un retorno superior al mercado, aumentando los ingresos e incrementando la diversificación de nuestros inversionistas. Ofrecemos un producto rentable y líquido, para que el inversionista pueda solicitar su dinero de vuelta en máximo 30 días.
                            </p>
                            <p><b>Nuestra misión es crear un fondo de inversión:</b></p>
                            <ul>
                                <li>Simple de entender y sin letra pequeña.</li>
                                <li>Que reduzca el riesgo país del portafolio del inversionista al mantener los fondos invertidos en mercados extranjeros.</li>
                                <li>Con incentivos alineados entre los inversionistas y la gestora del fondo, dado que Qubit Capital sólo ganará cuando se supere la rentabilidad objetivo de 5% trimestral. </li>
                                <div id='servicios'></div>
                            </ul>
                            
                        </div>
                        <div className='col-md-5 col-sm-0 col-xs-0 col-ghost'></div>
                    </div>
                </div>
                <div className='services_container'>
                    <div className='triangle_services'></div>
                    <div className='row g-0 container_body_p2'>
                        <div className='col-md-10 body_p1_text'>
                            <h4>QUÉ OFRECEMOS</h4>
                            <br></br>
                            <p>Un producto novedoso sin comisiones ni fees.<br/>
                            Rendimientos pagados en cuenta o con opción de reinvertir.<br/>
                            Rescate de capital sin comisión en menos de 30 días desde la solicitud.</p>
                            <p>Estructura de ganancias escalonada:</p>
                            
                            <ul>
                                <li>Primeros 5% trimestrales: 100% del inversionista menos el 5% de impuesto a la renta. Qubit realizará el pago a la SUNAT directamente.</li>
                                <li>Superior al 5% trimestral: Comisión de éxito por el uso exclusivo del algoritmo a la gestora Qubit Capital y a Benz Capital, empresa especializada en inversiones de oro y en CFD´s.</li>
                            </ul>
                            {/* --------------------------------*/}
                            <h4>QUÉ HACEMOS</h4>
                            <br></br>
                            <p>Qubit Capital gestiona fondos privados focalizados en commodities. Nuestro primer fondo es de oro (XAU/USD) 
                                con un monto máximo de inversión de US$ 100MM. 
                                <br />Compramos y vendemos CFD (contract for difference) en bolsas extranjeras con una estrategia de “high frequency trading” 
                                donde una posición promedio es creada y cerrada en ~1 día.
                                <br />Ofrecemos un ingreso pasivo y recurrente cada 3 meses; pagados en cuenta o capitalizable.</p>
                            <p>Qubit Capital S.A. gestiona fondos de inversión privada que cumplen con toda la regulación local. Los fondos de inversión privados no son supervisados por la Superintendencia del Mercado de Valores (SMV) de acuerdo con la legislación peruana vigente.</p>
                            <p className='body_p2_subtitle'><b>¿Cómo generamos ganancias?</b></p>
                                <ul>
                                    <li>Definimos la estrategia de inversión a través de un modelo algorítmico con discrecionalidad de nuestro head trader. 
                                        El modelo algorítmico ha sido construido para uso exclusivo de Quibit Capital. El modelo decodifica patrones globales del mercado de commodities, analiza movimientos de capital e información trascendente entre distintos commodities que afectan el performance del oro.
                                    </li>
                                    <li>Determinamos los patrones de comportamiento proyectado y tomamos decisiones de compra y venta; tanto si el precio suba o baje,
                                        de miles de posiciones de oro (XAU/USD).
                                    </li>
                                    <li>Operamos 20 horas al día en los mercados extranjeros más importantes como Wellington, Sydney, Tokio, Singapur, 
                                        Fráncfort, Londres y Nueva York.
                                    </li>
                                </ul>
                            <p className='body_p2_subtitle'><b>¿Cómo gestionamos el riesgo?</b></p>
                                <ul>
                                    <li><b>Riesgo de liquidez:</b> La gestión de liquidez es nuestro indicador prioritario. Se apalanca un máximo de 10% del portafolio para mantener un ratio de margen superior al 1,000%, cuando el ratio de realización o auto-call es de 50%. Así se evitar cualquier liquidación del portafolio o se permite cumplir con los rescates de los inversionistas.</li>
                                    <li><b>Riesgo de mercado:</b> Una elevada volatilidad del precio de oro, tanto si
                                        sube como si baja, beneficia a un modelo de high frequency trading. Se
                                        gestiona el riesgo de mercado mediante el uso de nuevas herramientas
                                        de trading para corregir posiciones en caso haya una diferencia
                                        significativa con nuestras posiciones abiertas y el margen en riesgo.
                                    </li>
                                    <li><b>Riesgo de contraparte:</b> Operamos a través de la plataforma FxPro, empresa regulada en Gran Bretaña hace más de 16 años.</li>
                                    <li><b>Riesgos de operación:</b> Acceso a mercados globales en tiempo real con tecnología propia para minimizar tiempos de ejecución y errores humanos</li>
                                    <li><b>Cumplimiento regulatorio:</b> Si bien un fondo privado no está supervisado por la SMV, Qubit Capital realiza la regulación correspondiente para detectar y evitar lavado de dinero.</li>
                                </ul>  
                            <div className='services_maps_text'>
                                <p>El siguiente gráfico del performance del Fondo 1 desde Julio 2022 muestra el rendimiento generado vs la volatilidad del precio de oro en el mercado:</p>
                                <ul className=''>
                                    <li>Línea Azul – Valor cuota: El valor generado en el tiempo que excede el dinero depositado en el Fondo.</li>
                                    <li>
                                    Barras grises – Precio de oro: El valor del oro (XAU) al término del día en mención
                                    </li>
                                </ul>
                                <img src={maps1} className="services_maps1" />
                                <img src={maps3} className="services_maps_ghost" />
                                <br></br>
                                <spam>Leyenda:</spam>
                                <ol>
                                    <li>Valor cuota o valor generado: Es la generación de valor por encima del dinero en efectivo que se ha depositado a la cuenta del Fondo. Se calcula: Balance de dinero en la cuenta del fondo / dinero en efectivo depositado a la cuenta del fondo.
                                    </li>
                                </ol>
                            </div>
                            <p className='body_p2_subtitle'><b>¿Qué resultados hemos logrado?</b></p>
                            <ul>
                                <li>
                                    Q2 2022: 24% de rentabilidad con nuestro portafolio de experimentación.
                                    </li>
                                <li>
                                    Q3 2022: Superamos el 22% de rentabilidad.
                                </li>
                                <li>
                                    Q4 2022: Superamos el 13% de rentabilidad.
                                </li>
                                <li>
                                    Q1 2023: Superamos el 9% de rentabilidad.
                                </li>

                            </ul>
                            {/*<div className='services_maps_text'>
                                <img src={maps2} className="services_maps2" />
                                <img src={maps4} className="services_maps_ghost"/>
                            </div> */}
                            <div id='nosotros'></div>
                        </div>
                        <div className='col-md-2'></div>
                    </div>
                </div>
                
                {/* ---------------- conócenos ------------------------------------------------------*/}
                 
                <div className='know_us_container'>
                    <div className='triangle_know_us'></div>
                    <div className='know_us_tittle'>
                        <h4>CONÓCENOS</h4>
                    </div>
                    <div className='row g-0 know_us_one'>
                        <div className='col-lg-8 col-md-8 col-sm-12'>
                            <div className='know_us_text'>
                                <h2>Erick Brandon Benzaquen</h2>
                                <h6>FOUNDER & HEAD TRADER</h6>
                                <ul>
                                    <li>Administrador de Florida International University (FIU).</li>
                                    <li>Head Trader en Giovani Gold SAC. 2011 - 2016.</li>
                                    <li>Head Trader en Qori Sumak SAC. 2013 - 2017.</li>
                                    <li>Trader privado con un portafolio de US$ 15MM con 20 cuentas de clientes privados
                                        en Perú, Canadá, Grecia e India. 2018 - 2022.</li>
                                    <li>Desarrollador y propietario del algoritmo focalizado en analizar los patrones de comportamiento
                                        proyectado del oro (XAU/USD) en base a información y movimientos de capital.</li>
                                </ul>
                            </div>
                        </div>
                
                        <div className='col-lg-4 col-md-4 col-sm-12'>
                            <div className='know_us_image'>
                                <img src={executive_one} className="img_executive" />
                            </div>
                            
                        </div>
                    </div>
                    <div className='row g-0 know_us_two'>
                        <div className='col-lg-4 col-md-4 col-sm-12'>
                            <div className='know_us_image'>
                                <img src={executive_two} className="img_executive" />
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-8 col-sm-12'>
                            <div className='know_us_text'>
                                <h2>Sergio Rubini Castro</h2>
                                <h6>FOUNDER & GERENTE GENERAL</h6>
                                <ul>
                                    <li>Economista de la Universidad del Pacífico con un MBA en London Business School.</li>
                                    <li>Experiencia profesional de +15 años trabajando en McKinsey y como miembro de la alta dirección
                                        en empresas internacionales de Finanzas, Retail y Minería.</li>
                                    <li>Fundador de MonkeyFit, startup focalizada en promover la actividad física entre colaboradores
                                        de una empresa para aumentar la productividad y reducir la siniestralidad de enfermedades.</li>
                                    <li>Trayectoria global al haber trabajado y vivido en 9 países en 3 distintos continentes.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-12 know_us_image_ghost'>
                            <img src={executive_two} className="img_executive" />
                        </div>
                    </div>
                    <div className='row g-0 know_us_three'>
                        <div className='col-lg-8 col-md-8 col-sm-12 '>
                            <div className='know_us_text'>
                            <h2>Hernan Gutierrez Ortiz</h2>
                            <h6>FOUNDER & GERENTE COMERCIAL</h6>
                            <ul>
                                <li>Abogado de la Universidad de Lima, MBA del PAD de la Universidad de Piura.</li>
                                <li>Emprendedor en serie con +15 años de experiencia.</li>
                            </ul>
                            <p>Socio fundador de las siguientes empresas:</p>
                            <ul>
                                <li>Agropecuaria Tadeo SAC (2010-2013) con salida exitosa a través de la venta del 100%.</li>
                                <li>Sociedad nacional del taxista peruano 2013-2020 (presidente del directorio 5 años).</li>
                                <li>Amawta LAB 2015-2019.</li>
                                <li>Desarrolladora Condorcanqui (2017-actual).</li>
                                <li>Consultores y abogados nacionales SAC (2019-actual).</li>
                                <li>Miembro del Directorio en Future Concept y MYD asesoría financiera.</li>
                            </ul>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12 '>
                            <div className='know_us_image'>
                                <img src={executive_three} className="img_executive" />
                            </div>
                        </div>
                    </div>
                </div>
                <div id='contactanos'></div>
                <Form_qubit/>
                
            </div>
        )
    }

}

export default HomePage;