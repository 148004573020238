import React from 'react';
import Footer from '../components/footer';
import NavBar from '../components/navbar2';
import '../styles/terminos_condiciones.css';


class TerminosCondiciones extends React.Component{

    render(){
        return(
            <div className='container_TC'>
                <NavBar/>
                <div className='TC_container_tittle'>
                    <h1>TÉRMINOS Y CONDICIONES</h1>
                    <h2>Términos y Condiciones Generales para el uso del sitio de internet de Qubit Capital S.A</h2>
                    <span>Versión vigente desde el 18 de noviembre de 2022.</span>
                </div>
                <div className='TC_container_body'>
                    {/* ----------------- 1 -------------------- */}
                    <div className='TC_contents_tittle'>
                        <h5>1. Términos Generales y su Aceptación </h5> 
                    </div>
                    <div className='TC_contents_text'>
                        <p>Los siguientes son los Términos Generales que regulan el uso de la información en formato digital y servicios de <a href='https://qubitcapitalgroup.com/ '>https://qubitcapitalgroup.com/ </a> (en adelante el "Sitio") que Qubit Capital S.A (en adelante "Qubit") pone a disposición de los usuarios de internet.</p>
                        <p>La utilización del sitio confiere la condición de usuario y conlleva la aceptación plena y sin reservas de todas y cada una de estos términos, desde el momento mismo del acceso al sitio. En consecuencia, el hecho de hacer uso de esta página web constituye la aceptación los mismos.</p>
                        <p>La utilización de ciertos servicios ofrecidos a los Usuarios a través del sitio está sometida a términos particulares, propios que, según los casos, sustituyen, completan y/o modifican los Términos Generales (en adelante los "Términos Particulares"), por lo que su uso constituye la aceptación a los Términos Particulares.</p>
                        <p>Estos Términos Generales constituyen un acuerdo completo entre el usuario y respecto al uso de esta página web y prevalece o reemplaza a otras comunicaciones acuerdos o propuestas verbales o escritas entre el usuario y la página web respecto únicamente del tema en cuestión. Qubit se reserva el derecho de modificar los Términos Generales de Uso del Sitio en cualquier momento, notificando al usuario la nueva versión con las modificatorias insertas mediante la publicación en la página web, presumiéndose la aceptación, en caso de continuar el usuario con la utilización del servicio.</p>
                    </div>
                    {/* -------------------  2 ------------------ */}
                    <div className='TC_contents_tittle'>
                        <h5>2. Objeto</h5>
                    </div>
                    <div className='TC_contents_text'>
                        <p>A través del Sitio, Qubit facilita a los usuarios el acceso y la utilización de diversos servicios y contenidos puestos a su disposición por Qubit o por terceros usuarios del Sitio y/o terceros proveedores de servicios y contenidos.</p>
                        <p>Qubit se reserva el derecho a dar término o modificar unilateralmente la configuración, presentación y condiciones de uso del Sitio o de sus servicios.</p>
                    </div>
                    {/* ------------------ 3 ------------------- */}
                    <div className='TC_contents_tittle'>
                        <h5>3. Condiciones de Acceso y Utilización del Sitio</h5>
                    </div>
                    <div className='TC_contents_text'>
                        <h5 className='TC_contents_subtittle'>3.1. Sobre el Acceso y Uso</h5>
                        <p>El Sitio es para uso personal, por lo que el usuario no se encuentra autorizado a modificar, copiar, distribuir, divulgar, transmitir, utilizar, reproducir, publicar, ceder, vender la información, el software, los productos o servicios que pueda obtener de este. </p>
                        <p>El Usuario se compromete a utilizar el Sitio y los servicios de conformidad con la ley, la moral y las buenas costumbres.</p>
                        <p>El Usuario se obliga a abstenerse de utilizar el Sitio y sus servicios con fines o efectos ilícitos, contrarios a lo establecido en estos Términos Generales, lesivos de los derechos e intereses de terceros, que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar el sitio y los servicios o impedir la normal utilización o disfrute del Sitio y de los servicios por parte de los Usuarios.</p>
                    </div>
                    <div className='TC_contents_text'>
                        <h5 className='TC_contents_subtittle'>3.2. Sobre el Contenido</h5>
                        <p>Si desea obtener información sobre cómo obtener el permiso del para la utilización de los contenidos, el Usuario deberá contactarse con Qubit. En caso de producirse violación a cualquiera de estos Términos Generales, el permiso para la utilización de los contenidos terminará automáticamente, debiendo el Usuario proceder inmediatamente a destruir las copias que haya realizado del material obtenido.</p>
                        <p>El Usuario se compromete a usar los contenidos de forma diligente, correcta y licita, absteniéndose de utilizarlos con fines contrarios a la ley, el orden público, la moral y las buenas costumbres.</p>
                    </div>
                    <div className='TC_contents_text'>
                        <h5 className='TC_contents_subtittle'>3.3. Propiedad Intelectual</h5>
                        <p>Los derechos de propiedad intelectual respecto de los servicios, contenidos, signos distintivos y dominio del Sitio, así como los derechos de uso y explotación de los mismos, incluyendo su divulgación, publicación, reproducción, distribución y transformación, son propiedad exclusiva de Qubit. El Usuario no adquiere ningún derecho por el simple uso de los servicios y contenidos del Sitio, y en ningún momento dicho uso será considerado como una autorización y/o licencia para la utilización de los mismos con fines distintos de los que se contemplan en los presentes términos de uso.</p>
                    </div>
                    <div className='TC_contents_text'>
                        <h5 className='TC_contents_subtittle'>3.4. Propiedad Intelectual de Terceros</h5>
                        <p>El Usuario acuerda que las disposiciones establecidas en el párrafo anterior, respecto a la titularidad de los derechos de Qubit, también son aplicables a los derechos de terceros, respecto de los servicios y contenidos de las páginas enlazadas al Sitio.</p>
                    </div>
                    <div className='TC_contents_text'>
                        <h5 className='TC_contents_subtittle'>3.5. Uso de Servicios</h5>
                        <p>Qubit se reserva algunos de los servicios ofrecidos a través del Sitio, a los usuarios registrados mediante el correspondiente llenado del registro de Usuarios, a disposición de los Usuarios que deseen registrarse.</p>
                        <p>Los Usuarios se comprometen a seleccionar, usar y conservar su nombre de Usuario (en adelante login) y su contraseña (en adelante password).</p>
                    </div>
                    <div className='TC_contents_text'>
                        <h5 className='TC_contents_subtittle'>3.6. Asignación de las Claves de Acceso</h5>
                        <p>Qubit podrá asignar a los Usuarios claves de acceso a contenido especial, reservado o confidencial, las que serán comunicadas a través de e-mails a sus respectivos usuarios. El Usuario podrá en cualquier momento cambiarlas por cualesquiera otras; sin embargo no podrá elegir palabras, expresiones o conjunto grafico-denominativos malsonantes, injuriosos, coincidentes con marcas, nombres comerciales, rótulos de establecimientos, denominaciones sociales, expresiones publicitarias, nombres y seudónimos de personajes de relevancia pública o famosos para cuya utilización no esté autorizado y en general, contrarios a la ley, el orden público, la moral y las buenas costumbres.</p>
                    </div>
                    <div className='TC_contents_text'>
                        <h5 className='TC_contents_subtittle'>3.7. Uso y Custodia</h5>
                        <p>El usuario se compromete a hacer un uso diligente de las claves de acceso, así como a no poner a disposición de terceros sus claves de acceso.</p>
                        <p>El usuario se compromete a comunicar a Qubit, a la brevedad posible la pérdida o robo de las claves de acceso, así como cualquier riesgo de acceso a las claves por un tercero.</p>
                    </div>
                    <div className='TC_contents_text'>
                        <h5 className='TC_contents_subtittle'>3.8. Uso de los servicios ofrecidos en el Sitio de conformidad con la política Anti Spamming</h5>
                        <p>El Usuario se compromete a abstenerse de lo siguiente:</p>
                        <ul>
                            <li>Remitir publicidad de cualquier clase y comunicaciones con fines de venta u otras de naturaleza comercial a una pluralidad de personas sin que medie su previa solicitud o consentimiento.</li>
                            <li>Remitir cualesquiera otros mensajes no solicitados ni consentidos previamente a una pluralidad de personas.</li>
                            <li>Enviar cadenas de mensajes electrónicos no solicitados ni previamente consentidos.</li>
                            <li>Utilizar listas de distribución a las que pueda accederse a través del Sitio o de los servicios para la realización de las actividades señaladas en los ítems anteriores.</li>
                            <li>Poner a disposición de terceros, con cualquier finalidad, datos captados a partir de listas de distribución.</li>
                            <li>Los usuarios perjudicados por la recepción de mensajes no solicitados, dirigidos a una pluralidad de personas podrán comunicarlo a Qubit, remitiendo un mensaje a la cuenta de correo s.rubini@qubitcapitalgroup.com.</li>
                        </ul>
                    </div>
                    <div className='TC_contents_text'>
                        <h5 className='TC_contents_subtittle'>3.9. Hiperenlaces</h5>
                        <p>El Sitio contiene enlaces a otros sitios de Internet que son mantenidos por terceros.</p>
                        <p>Los Usuarios y en general, aquellas personas que se propongan establecer un hiperenlace entre su página web y el Sitio (en adelante el "Hiperenlace"), deberán cumplir las condiciones siguientes:</p>
                        <ul>
                            <li>El Hiperenlace únicamente permitirá el acceso a las páginas web del Sitio, pero no podrá reproducirlas de ninguna forma.</li>
                            <li>No se realizarán manifestaciones o indicaciones falsas, inexactas o incorrectas sobre el Sitio, del servicios y en particular no se declarará ni dará a entender que ha autorizado el Hiperenlace o que ha supervisado o asumido de cualquier forma los contenidos o servicios ofrecidos o puestos a disposición de la página web en la que se establece el Hiperenlace.</li>
                            <li>A excepción de aquellos signos que formen parte del mismo Hiperenlace, la página web en la que se establezca el hiperenlace no contendrá ninguna marca, nombre comercial, rótulo de establecimiento, denominación, logotipo, eslogan u otros signos distintivos pertenecientes a Qubit.</li>
                            <li>La página web en la que se establezca el Hiperenlace no contendrá informaciones o contenidos ilícitos, contrarios al orden público, la moral y las buenas costumbres, así como tampoco contendrá contenidos contrarios a cualesquiera derechos de terceros.</li>
                        </ul>
                        <p>El establecimiento del Hiperenlace no implica en ningún caso, la existencia de relaciones entre Qubit y el sitio de Internet de terceros en la que se establezca, ni la aceptación y aprobación por parte de Qubit de sus contenidos o servicios.</p>
                    </div>
                    <div className='TC_contents_text'>
                        <h5 className='TC_contents_subtittle'>3.10. Enlaces que permitan el acceso a otras páginas web desde el Sitio</h5>
                        <p>El Sitio podrá proporcionar enlaces a otros sitios o recursos en la world wide web. Debido a que Qubit no tiene control sobre dichos sitios y recursos, el usuario acepta y acuerda que no sea responsable por la disponibilidad de dichos sitios y recursos externos, y no los respalda ni es responsable o tiene obligación por el contenido, comercialización, productos u otros materiales en los sitios o recursos, o disponibles desde esos sitios. Además, el Usuario acepta y acuerda que Qubit  no será responsable ni estará obligado, directa o indirectamente, por ningún daño o pérdida causada, por o en conexión con el uso o confianza en cualquier contenido, bienes o servicios disponibles a través de cualquier sitio.</p>
                    </div>

                    {/* ------------------ 4 ------------------- */}
                    <div className='TC_contents_tittle'> 
                        <h5>4. Datos de Carácter Personal</h5>
                    </div>
                    <div className='TC_contents_text'>
                        <p>Para utilizar algunos de los servicios, los Usuarios deben proporcionar previamente a Qubit ciertos datos de carácter personal. El tratamiento de los datos personales se rige por la Política de Privacidad.</p>
                    </div>
                    {/* ------------------ 5 ------------------- */}
                    <div className='TC_contents_tittle'> 
                        <h5>5. Utilización del Sitio, de los servicios y de los contenidos bajo la exclusiva responsabilidad del usuario</h5>
                    </div>
                    <div className='TC_contents_text'>
                        <p>El Usuario se encuentra consciente y acepta voluntariamente que el uso del Sitio, de los servicios y de los contenidos tiene lugar bajo su única y exclusiva responsabilidad.</p>
                    </div>
                    {/* ------------------ 6 ------------------- */}
                    <div className='TC_contents_tittle'>
                        <h5>6. Exclusión de Garantías y Responsabilidad</h5>
                    </div>
                    <div className='TC_contents_text'>
                        <h5 className='TC_contents_subtittle'>6.1. Disponibilidad y continuidad, utilidad y falibilidad</h5>
                        <p>Qubit no garantiza la disponibilidad y continuidad del funcionamiento del Sitio y de los servicios.</p>
                        <p>Cuando ello sea razonablemente posible, Qubit advertirá previamente las interrupciones en el funcionamiento del Sitio y de los servicios. Qubit  tampoco garantiza la utilidad del sitio y de los servicios para la realización de ninguna actividad en particular, ni de su infalibilidad del mismo. Qubit se exime de responsabilidad respecto de la accesibilidad, por parte de los Usuarios, a las distintas páginas web con hiperenlace al Sitio.</p>
                        <p>Por ningun motivo Qubit asumirá responsabilidad por los daños y perjuicios que puedan deberse a la falta de disponibilidad o de continuidad del funcionamiento del Sitio y de los servicios, a la defraudación de la utilidad que los Usuarios hubieren podido atribuir al sitio y a los servicios, a la falibilidad del Sitio y de los servicios, y en particular, aunque no de modo exclusivo, a los fallos en el acceso a las distintas páginas web del Sitio o a aquellas desde las que se prestan los servicios.</p>
                    </div>
                    <div className='TC_contents_text'>
                        <h5 className='TC_contents_subtittle'>6.2. Daños en sistemas informáticos</h5>
                        <p>Qubit no controla ni garantiza la ausencia de virus ni de otros elementos en los contenidos del Sitio que puedan producir alteraciones en su sistema informático (software y hardware) o en los documentos electrónicos y ficheros almacenados en su sistema informático. Qubit no asumirá responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la presencia de virus o a la presencia de otros elementos en los contenidos, que puedan producir alteraciones en el sistema informático, documentos electrónicos o ficheros de los Usuarios.</p>
                    </div>
                    <div className='TC_contents_text'>
                        <h5 className='TC_contents_subtittle'>6.3. Licitud, Fiabilidad y Utilidad</h5>
                        <p>Qubit no asumirá responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso a los contenidos, y en particular, aunque no de modo exclusivo, por los daños y perjuicios ocasionados por:</p>
                        <ul>
                            <li>El incumplimiento, por los Usuarios, de la ley, el orden público, la moral y las buenas costumbres como consecuencia de la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso a los contenidos.</li>
                            <li>La infracción, por los Usuarios, de los derechos de propiedad intelectual e industrial de los secretos empresariales, de compromisos contractuales de cualquier clase, de los derechos al honor, a la intimidad personal y familiar y a la imagen de las personas, de los derechos de propiedad y de toda otra naturaleza pertenecientes a un tercero como consecuencia de la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso a los contenidos.</li>
                            <li>La realización, por los Usuarios,  de actos de competencia desleal y publicidad engañosa como consecuencia de la transmisión, difusión, almacenamiento, puesta a disposición, recepción, obtención o acceso a los contenidos.</li>
                            <li>La falta de veracidad, exactitud, exhaustividad, pertinencia y/o actualidad de los contenidos.</li>
                            <li>La no adecuación para cualquier clase de propósito y la defraudación de las expectativas generadas por los contenidos.</li>
                            <li>El incumplimiento, retraso en el cumplimiento, cumplimiento defectuoso o terminación por cualquier causa de las obligaciones contraídas por terceros y contratos realizados con terceros a través de los contenidos o con motivo del acceso a los contenidos.</li>
                            <li>Los vicios y defectos de toda clase de los contenidos transmitidos, difundidos, almacenados, puestos a disposición o de otra forma transmitidos o puestos a disposición, recibidos, obtenidos o a los que se haya accedido a través del sitio o de los servicios.</li>
                        </ul>
                    </div>
                    {/* ------------------ 7 ------------------- */}
                    <div className='TC_contents_tittle'> 
                        <h5>7. Denegación y Retirada del Acceso al Sitio y/o los Servicios</h5>
                    </div>
                    <div className='TC_contents_text'>
                        <p>Qubit se reserva el derecho a denegar o retirar el acceso al Sitio o a los servicios en cualquier momento y sin necesidad de pre aviso, a aquellos Usuarios que incumplan estos Términos Generales o los Términos Particulares que resulten de aplicación.</p>
                    </div>
                    {/* ------------------ 8 ------------------- */}
                    <div className='TC_contents_tittle'>
                        <h5>8. Procedimiento en Caso de Realización de actividades de Carácter Ilícito</h5>
                    </div>
                    <div className='TC_contents_text'>
                        <p>En el caso de que cualquier Usuario o un tercero considere que existen hechos o circunstancias que revelen el carácter ilícito de la utilización de cualquier contenido y/o de la realización de cualquier actividad en las páginas web incluidas en o accesibles a través del Sitio y, en particular, de la violación de derechos de propiedad intelectual o industrial (patentes, modelos y dibujos industriales, marcas y nombres comerciales, etc.) u otros derechos, deberán enviar una notificación a Qubit  en la que se contengan los siguientes extremos:</p>
                        <ul>
                            <li>Datos Personales: nombre, dirección, número de teléfono y dirección de correo electrónicos del reclamante.</li>
                            <li>Especificación de la supuesta actividad ilícita llevada a cabo en el Sitio y, en particular, cuando se trate de una supuesta violación de derechos, indicación precisa y concreta de los contenidos protegidos, así como de su localización en las páginas web.</li>
                            <li>Violación de derechos, firma auténtica o equivalente, con los datos personales del titular de los derechos supuestamente infringidos o de la persona autorizada para actuar en nombre y por cuenta de ésta.</li>
                            <li>Declaración expresa, clara y bajo la responsabilidad del reclamante de que la información proporcionada en la notificación es exacta y del carácter ilícito de la utilización de los contenidos o de la realización de las actividades descritas.</li>
                            <li>Estas notificaciones deberán ser enviadas a la dirección de Qubit s.rubini@qubitcapitalgroup.com</li>
                        </ul>
                    </div>
                    {/* ------------------ 9 ------------------- */}
                    <div className='TC_contents_tittle'>
                        <h5>9. Ley Aplicable y Jurisdicción</h5>
                    </div>
                    <div className='TC_contents_text'>
                        <p>Qubit tiene su sede principal en del distrito de Miraflores, Lima, Perú. Todos los asuntos legales que surjan o estén relacionados con el Sitio, deberán ser atendidos con y según lo determinado por la legislación peruana. El Usuario acepta someterse a la jurisdicción de los tribunales de Lima Cercado en cualquier proceso o acción legal. Si alguna disposición de estos Términos y Condiciones  es declarada nula o no ejecutable por alguna autoridad, la invalidación de dicha disposición no afectará la validez de las disposiciones restantes, y las demás disposiciones se mantendrán con plena vigencia.</p>
                    </div>

                </div>
                <Footer/>
            </div>
        )
            
                
    }

}

export default TerminosCondiciones;