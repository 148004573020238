import React from 'react';
import Footer_img from '../assets/img/logo.png';

import Libro_reclamaciones from '../assets/img/libro_de_reclamaciones.png'

import '../styles/footer.css';

class Footer extends React.Component{

    render(){
        return(
            <div className='footer_container'>
                <div className='row g-0 footer_content'>
                    <div className='col-md-4 col-sm-12  col-xs-12 footer_logo'>
                        <a className="" href="/home"><img src={Footer_img} className="" /></a>
                    </div>
                    <div className='col-md-4 col-sm-12 col-xs-12 footer_text1'>
                        <div className='footer_text'>
                            <div className='footer_tittle'>
                                <h6>CONTACTO</h6>
                            </div>
                            <div className='footer_text_ayb'>
                                <div className='footer_text_a'>
                                    <p>Sergio Rubini CEO</p><br></br>
                                    <p>+51 949 527 976</p><br></br>
                                    <p>s.rubini@qubitcapitalgroup.com</p>
                                </div>
                                <div className='footer_text_b'>
                                    <p>Hernán Gutiérrez CCO</p><br></br>
                                    <p>+51 995 652 977</p><br></br>
                                    <p>hgutierrez@qubitcapitalgroup.com</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className='col-md-4 col-sm-12 col-xs-12 footer_text2'>
                        <div className='footer_text'>
                            <div className='footer_text_a'>
                                <a href='../terminos-y-condiciones'><p>Términos y condiciones</p></a>
                            </div>
                            <div className='footer_text_b'>
                                <a href='../politica-de-privacidad'><p>Política y privacidad</p></a>
                            </div>
                            <div className='libro_reclamaciones'>
                            <a href='https://librodereclamaciones.pe/' type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"><img src={Libro_reclamaciones} className="" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer_end'>
                    <p>© 2022 QUBIT. All rights reserved</p>
                </div>
            </div>
        )
            
                
    }

}

export default Footer;