import React from 'react';
import * as $ from 'jquery';

import logo from '../assets/img/logo.png';
import '../styles/navbar2.css';

import * as Scroll from 'react-scroll';


class NavBar2 extends React.Component{
    constructor(props){
        super(props);
        this.state = {value: ''};
        $(document).ready(()=>{
            $(window).scroll(function() {
                if ($(window).scrollTop() > 10) {
                  $('.contenedor_navbar2').css({'position':'fixed'});
                } else {
                    $('.contenedor_navbar2').css({'position':'relative'});
                }
            });
        })
    }
        render(){
            return(
                    <div className="contenedor_navbar2">
                        <nav className="navbar navbar-expand-lg  navbar-light ">
                            <div className="logo_qubit2">
                                <a className="" href="/home"><img src={logo} className="logo_nav" /></a>
                            </div>
                            
                            {/*Btn de menu movil*/}
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                            </button>
    
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">

                                <ul className="navbar-nav">
                                    <li className="nav-item active">
                                        <a href='/home'  className="nav-link">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a  href='/home' className="nav-link">Servicios</a>
                                    </li>                                
                            
                                    <li className="nav-item">
                                        <a  href='/home' className="nav-link">Nosotros</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href='/home' className="nav-link">Contáctanos</a>
                                    </li>
                                    <li className='rrss_nav'>
                                        <a href='https://www.linkedin.com/company/qubit-capital-group/?viewAsMember=true' className="nav-link" style={{width: "100%"}}><i class="fab fa-linkedin-in"></i></a>
                                    </li>
    
                                </ul>
                                  {/**<div className="rrss_nav">
                                   <div className="enlace_rrss">
                                        <i className="fab fa-facebook-square">I</i>
                                    </div>
                                    <div className="enlace_rrss">
                                        <i className="fab fa-instagram">B</i>
                                    </div> */}
                                
                                {/*boton de inision de sesion 
                                <div>
                                    <a name="" id="" class="btn btn-primary" href="#" role="button">Iniciar Sesión</a>                
                                </div>*/}
                            </div>
                                

                        </nav>
                        {/*<div className="barra_rrss">
                        
                        <div className="rrss_nav">
                                <div className="enlace_rrss">
                                <i className="fab fa-facebook-square"></i>
                                </div>
                                <div className="enlace_rrss">
                                <i className="fab fa-instagram"></i>
                                </div>
                                
                                <div className="enlace_rrss">
                                <i className="fab fa-linkedin"></i>
                                </div>    
                            </div> 
                            
                        </div>*/}
                        
                    </div>
                    
            
            )
        }
    
 }

 export default NavBar2;