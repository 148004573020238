import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

//import 'react-confirm-alert/src/react-confirm-alert.css';

import $ from "jquery";

import RouterApp from './App';
 
const container = document.getElementById('root');

ReactDOM.render(<RouterApp/>,container);
