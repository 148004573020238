import React from 'react';

import '../styles/form_qubit.css';

class Form_qubit extends React.Component{

    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        fetch('https://qubit.imbamanager.com/API-REST/controller/contacto.php?op=registrar',
        {method: 'POST',body: data}
        ).then(res=>res.text()).then(
        (data)=>{
            if(data=="true"){
                alert("Sus datos fueron enviados. Nos pondremos en comunicación con usted.");
                window.location.reload();
            }else{
                alert("Ocurrio un problema, revise su conexión.");
            }
        },
        (error)=>{
            console.log(error);
        }
        );
       
    }

    render(){
        return(
            <div className='form_container'>
                <div className='triangle_form'></div>
                <form className='row g-0 form_content' onSubmit={this.handleSubmit}>
                    <div className='col-md-5 form_text'>
                        <h4>Contáctanos</h4>
                        <p>¡Gracias por su interés en QUBIT CAPITAL! <br/>¿Desea obtener más información sobre 
                            nosotros? Envíe este 
                            formulario y nuestro representante se comunicará con usted pronto.</p>
                    </div>
                    <div className='col-md-7 form_contact_us'>
                        <div className='row g-2'>
                            <div className='col-md'>
                                <div class="form-floating mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Nombres</label>
                                    <input type="text" class="form-control" id="nombre" name="nombre" maxLength="40" required/>
                                </div> 
                            </div>
                            <div className='col-md'>
                                <div class="form-floating mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Apellidos</label>
                                    <input type="text" class="form-control" id="apellidos" name="apellidos" maxLength="40" required/>
                                </div> 
                            </div>
                        </div>
                        <div className='row g-2'>
                            <div className='col-md'>
                                <div class="form-floating mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Email</label>
                                    <input type="email" class="form-control" id="correo" name="correo" maxLength="60" required/>
                                </div> 
                            </div>
                            <div className='col-md'>
                               <div class="form-floating mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">Teléfono</label>
                                    <input type="text" class="form-control" id="telefono" name="telefono" maxLength="15" required/>
                                </div> 
                            </div>
                        </div>
                        <div class="form-floating mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Coméntanos</label>
                            <textarea class="form-control" id="observacion" name="observacion" rows="3" required></textarea>
                        </div>
                        <button type="submit" class="btn btn-primary"><i className="fas fa-arrow-right"></i> Enviar</button>

                    </div>
                </form>
            </div>
        )
    }    


}

export default Form_qubit;