import React from 'react';
import {Route} from 'react-router-dom';

import Navbar from '../components/navbar';
import Footer from '../components/footer';

const PaginaLayout = ({children}) =>{
    return(
        <div>
            <Navbar />
            <div className="contenedor_vista">
                {children}
            </div>
            <Footer />
            
        </div>
    )
}

const PaginaLayoutRoute = ({component: Component}) => {
    return (
        <Route render={matchProps => (
                <PaginaLayout>
                    <Component />
                </PaginaLayout>
            )}

        />
    )
}

export default PaginaLayoutRoute;