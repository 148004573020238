import React from 'react';
import * as $ from 'jquery';

import logo from '../assets/img/logo.png';
import '../styles/navbar.css';

import * as Scroll from 'react-scroll';


class NavBar extends React.Component{

    Link      = Scroll.Link;
    Button    = Scroll.Button;
    Element   = Scroll.Element;
    Events    = Scroll.Events;
    scroll    = Scroll.animateScroll;
    scrollSpy = Scroll.scrollSpy;

        constructor(props){
            super(props);
            this.state = {value: ''};
            $(document).ready(()=>{
                $(window).scroll(function() {
                    if ($(window).scrollTop() > 10) {
                        
                      $('.logo_nav').stop().animate({'width':'50px'},600);
                      $('.logo_nav').stop().animate({'width':'50px'},600);
                      $('.contenedor_navbar').css({'background-color':'#05487c','padding':'0px 3%'});
                      $('.navbar').css({'align-items':'center'});
                    } else {
                      //$('ul li .nav-link').css('color','#BF8F00');
                        if($(window).width()<450) {
                            $('.logo_nav').stop().animate({'width':'100px'});
                        } else {
                            $('.logo_nav').stop().animate({'width':'120px'});
                        }
                        $('.navbar').css({'align-items':'flex-start'});
                        $('.contenedor_navbar').css({'background-color':'transparent', 'padding':'3%'});
                    }
                });
            })
        }

        componentDidMount() {
            this.Events.scrollEvent.register('begin', function(to, element) {
              console.log('begin', arguments);
            });
        
            this.Events.scrollEvent.register('end', function(to, element) {
              console.log('end', arguments);
            });
        
            this.scrollSpy.update();
          }
        
        componentWillUnmount() {
            this.Events.scrollEvent.remove('begin');
            this.Events.scrollEvent.remove('end');
          }

        scrollToTop() {
            this.scroll.scrollToTop();
        }

        scrollToBottom() {
            this.scroll.scrollToBottom();
        }
        
        scrollTo() {
            this.scroll.scrollTo(300);
        }
          
        scrollMore() {
            this.scroll.scrollMore(300);
        }
        
        handleSetActive(to) {
            this.console.log(to);
        }
        

        render(){
            return(
        
                    <div className="contenedor_navbar">
                        <nav className="navbar navbar-expand-lg  navbar-light ">
                            <div className="logo_qubit">
                                <a className="" href="/home"><img src={logo} className="logo_nav" /></a>
                            </div>
                            
                            {/*Btn de menu movil*/}
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                            </button>
    
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    
                                <ul className="navbar-nav">
                                    <li className="nav-item active">
                                        <this.Link to="home" spy={true} smooth={true} offset={50} duration={700} className="nav-link">Home</this.Link>
                                    </li>
                                    <li className="nav-item">
                                        <this.Link to="servicios" spy={true} smooth={true} offset={50} duration={700} className="nav-link">Servicios</this.Link>
                                    </li>                                
                            
                                    <li className="nav-item">
                                        <this.Link to="nosotros" spy={true} smooth={true} offset={50} duration={700} className="nav-link">Nosotros</this.Link>
                                    </li>
                                    <li className="nav-item">
                                        <this.Link to="contactanos" spy={true} smooth={true} offset={50} duration={700} className="nav-link">Contáctanos</this.Link>
                                    </li>
                                    <li className='rrss_nav'>
                                        <a href='https://www.linkedin.com/company/qubit-capital-group/?viewAsMember=true' className="nav-link" style={{width: "100%"}}><i class="fab fa-linkedin-in"></i></a>
                                    </li>
                                    <li className='intranet-link'>
                                        <a className="btn btn-light" href='https://qubit-intranet.web.app/'>Intranet</a>
                                    </li>
    
                                </ul>
                                  {/**<div className="rrss_nav">
                                   <div className="enlace_rrss">
                                        <i className="fab fa-facebook-square">I</i>
                                    </div>
                                    <div className="enlace_rrss">
                                        <i className="fab fa-instagram">B</i>
                                    </div> */}
                                
                                {/*boton de inision de sesion 
                                <div>
                                    <a name="" id="" class="btn btn-primary" href="#" role="button">Iniciar Sesión</a>                
                                </div>*/}
                            </div>
                                

                        </nav>
                        {/*<div className="barra_rrss">
                        
                        <div className="rrss_nav">
                                <div className="enlace_rrss">
                                <i className="fab fa-facebook-square"></i>
                                </div>
                                <div className="enlace_rrss">
                                <i className="fab fa-instagram"></i>
                                </div>
                                
                                <div className="enlace_rrss">
                                <i className="fab fa-linkedin"></i>
                                </div>    
                            </div> 
                            
                        </div>*/}
                        
                    </div>
                    
            
            )
        }
    
 }

 export default NavBar;